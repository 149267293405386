<template>
  <div class="h-100" id="qa-badge-check-view">
    <b-container
      fluid
      :class="{
        'ob-container h-100': true,
        'container-invalid': !showVerify && !validCredential
      }"
    >
      <b-row class="h-100">
        <b-col cols="12" lg="4" class="p-0 vc-left">
          <b-row class="ob-left__div h-100">
            <b-col align-v="start" cols="6" lg="12" class="pl-2 pl-lg-0">
              <div class="d-flex h-100 align-items-start">
                <img
                  src="@/assets/images/verification-icon.svg"
                  alt="verification icon"
                  width="auto"
                  height="auto"
                />
              </div>
            </b-col>
            <b-col align-v="end" cols="6" lg="12" class="pr-2 pr-lg-0">
              <div
                class="d-flex h-100 align-items-lg-end align-items-center justify-content-end justify-content-lg-start"
              >
                <img
                  src="@/assets/images/logo/whoa-logo-white-en.svg"
                  alt="Logo"
                  width="140"
                  height="auto"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="8" class="p-0 vc-right">
          <div class="ob-right__div h-100">
            <div class="h-100 w-100 verify-credentials" v-if="showVerify">
              <div class="w-100 text-left">
                <h3 class="">Verify Credential</h3>
                <div class="d-flex vc-heading">
                  <img
                    src="@/assets/images/icons/url.svg"
                    alt="icons"
                    width="auto"
                    height="auto"
                  />
                  <label class="ml-3 mb-0">Using the Credential URL</label>
                </div>
                <Input
                  id="credential-url"
                  label="Credential URL"
                  placeholder="Input text"
                  :pre-ob="true"
                  className="mb-1"
                  :state="nameStateUrl"
                  v-model="credentialUrl"
                  :showError="
                    !credentialUrlError && credentialUrl
                      ? credentialUrl.length > 0
                      : false
                  "
                  error-message="Make sure the URL format is
                  https:www.domain.com/example"
                />
                <span
                  class="vc-notes"
                  v-if="credentialUrlError || !credentialUrl"
                  >Paste the URL of a hosted badge, its JSON, or JWS
                  crytographic signature.</span
                >
                <div class="d-flex mt-5 vc-heading">
                  <img
                    src="@/assets/images/icons/image.svg"
                    alt="credential image"
                    width="auto"
                    height="auto"
                  />
                  <label class="ml-3 mb-0"
                    >Alternatively you can upload the Credential Image</label
                  >
                </div>
                <div>
                  <input
                    type="file"
                    name="credential-file"
                    id="credential-file"
                    class="upload-file"
                    @change="onFileUpload"
                    accept=".png, .svg"
                  />
                  <label
                    class="mb-0 btn ob-btn ob-btn-outline"
                    for="credential-file"
                  >
                    Upload Image
                  </label>
                  <span
                    :class="{
                      'vc-upload__text': true,
                      'file-name': credentialFile
                    }"
                  >
                    {{ fileName }}
                  </span>
                </div>
              </div>
              <div class="w-100 text-left">
                <h3 class="">
                  Verify Learner’s Identity <b-badge>Optional</b-badge>
                </h3>
                <div class="d-flex vc-heading">
                  <img
                    src="@/assets/images/icons/email.svg"
                    alt="email"
                    width="auto"
                    height="auto"
                  />
                  <label class="ml-3 mb-0">Using the email address</label>
                </div>
                <Input
                  id="email"
                  label="Email"
                  placeholder="Input text"
                  :pre-ob="true"
                  className="mb-1"
                  v-model="email"
                  :state="nameStateEmail"
                  :showError="!emailError && email ? email.length > 0 : false"
                  errorMessage="Incorrect email address. Make sure the format is
                  name@address.com"
                />
                <span class="vc-notes" v-if="emailError || !email"
                  >Use the email address that the learner used to sign up on
                  this platform</span
                >
              </div>
              <b-button
                class="ob-btn ob-btn-primary"
                @click="showVerify = false"
                >Verify</b-button
              >
            </div>
            <div
              class="h-100 w-100 credential-detail text-left"
              v-if="!showVerify"
            >
              <div
                class="d-flex align-items-start align-items-lg-center mb-5"
                v-if="validCredential"
              >
                <img
                  src="@/assets/images/verified.svg"
                  alt="verified"
                  width="auto"
                  height="auto"
                />
                <h5 class="mb-0 ml-2">This is a Valid Credential</h5>
              </div>
              <div
                class="d-flex align-items-start align-items-lg-center mb-5"
                v-else
              >
                <img
                  src="@/assets/images/invalid-credential.svg"
                  alt="invalid credential"
                  width="auto"
                  height="auto"
                />
                <h5 class="mb-0 ml-2">
                  We could not match the details provided with our records
                </h5>
              </div>
              <div class="cd-details">
                <div v-if="validCredential">
                  <label class="cd-details__label">About the Credential:</label>
                  <h5 class="mb-5">
                    Infection Prevention and Control (IPC) core components and
                    guidelines
                  </h5>
                  <div class="cd-detail__name mb-5">
                    <p>Julie Harley</p>
                    <span class="cd-detail__notes"
                      >Learner Not Verified. Enter their email address on the
                      previous step.</span
                    >
                  </div>
                  <div>
                    <b-row class="badge-main__det mb-4">
                      <b-col
                        cols="12"
                        lg="6"
                        class="badge-det__left mb-5 mb-lg-0"
                      >
                        <div class="badge-det__div">
                          <label>Issue date</label>
                          <p>(Valid from)</p>
                        </div>
                        <div class="badge-det__div">
                          <label>Valid To</label>
                          <p>(Expiration of certificate)</p>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="6" class="badge-det__right">
                        <div class="badge-det__div">
                          <label>Digital Signature</label>
                          <p>(WHOA digital signature)</p>
                        </div>
                        <div class="badge-det__div">
                          <label>Serial number/ ID</label>
                          <p>87453786500</p>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="cd-invalid__credential" v-else>
                  <h5>Reason of Failed Verification</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat.
                  </p>
                  <label class="">Restart Verification</label>
                </div>
                <div class="cd-details__issuer">
                  <label class="cd-details__label">About the Issuer:</label>
                  <h3>WHO Academy</h3>
                  <div class="d-flex cd-details__links">
                    <a>WEBSITE</a>
                    <a>Email</a>
                    <a>View full issuer details</a>
                  </div>
                </div>
                <div class="cd-details__issue mb-3">
                  <p>
                    Report any validation bugs or incorrect result to the
                    <span>development team</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Input from "../components/WrapperComponents/Input";
export default {
  components: { Input },
  data() {
    return {
      credentialUrl: null,
      credentialFile: null,
      email: null,
      showVerify: true,
      validCredential: true
    };
  },
  computed: {
    credentialUrlError() {
      let regex = new RegExp(
        "^http(s)?://(www.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
      );
      return regex.test(this.credentialUrl);
    },
    emailError() {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(this.email);
    },
    nameStateUrl() {
      return this.credentialUrlError ? true : null;
    },
    nameStateEmail() {
      return this.emailError ? true : null;
    },
    fileName() {
      return this.credentialFile
        ? this.credentialFile.name
        : "No File Selected";
    }
  },
  created() {
    this.$emit("update:layout", "main");
  },
  methods: {
    onFileUpload(e) {
      this.credentialFile = e.target.files[0];
    }
  }
};
</script>

<style lang="scss">
.ob-container {
  .vc-left,
  .vc-right {
    height: 100%;
  }
  &.container-invalid {
    .ob-left__div {
      background: #f44336;
    }
  }
  .ob-left__div {
    background: #1c499a;
    padding: 20% 16% 16% 12%;
    text-align: left;

    .ob-left__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #c4c4c4;
      margin-bottom: 90px;
    }
    .ob-left__value {
      letter-spacing: 1.25px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c4c4c4;
    }
  }
  .ob-right__div {
    padding: 5% 10%;
    // overflow-y: auto;
    display: flex;
    .verify-credentials {
      text-align: left;
      .text-left {
        margin-bottom: 70px;
        h3 {
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 26px;
          line-height: 32px;
          letter-spacing: 0.2px;
          color: #000000;
          display: flex;
          align-items: center;
        }
        .badge {
          background: #efefef;
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: #828282;
          border-radius: 100px;
          padding: 3px 16px;
          margin-left: 20px;
        }
        .vc-notes {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #52575c;
          padding-left: 12px;
          &.vc-error {
            color: #f44336;
          }
        }
        .vc-upload__text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #52575c;
          margin-left: 25px;
          &.file-name {
            color: #00c781;
          }
        }
        .vc-heading {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #0043ac;
          margin-bottom: 30px;
        }
        .upload-file {
          width: 0;
          visibility: hidden;
        }
      }
    }
    .credential-detail {
      .cd-invalid__credential {
        margin-bottom: 10%;
        h5 {
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.4px;
          color: #f44336;
        }
        p {
          font-weight: normal;
          font-size: 13px;
          line-height: 140%;
          color: #52575c;
        }
        label {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: #0043ac;
          text-transform: uppercase;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #000000;
      }
      .cd-details {
        .cd-details__label {
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.4px;
          color: #757575;
          margin-bottom: 20px;
        }
        .cd-detail__name {
          p {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.4px;
            color: #000000;
            margin-bottom: 5px;
          }
          .cd-detail__notes {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: #f44336;
            &.cd-success {
              color: #00c781;
            }
          }
        }
        .cd-details__issuer {
          margin-bottom: 25%;
          h3 {
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.1px;
            color: #000000;
          }
          .cd-details__links {
            margin: 20px 0;
            a {
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.4px;
              color: #0043ac;
              margin-right: 50px;
              text-transform: uppercase;
            }
          }
        }
        .cd-details__issue {
          position: fixed;
          bottom: 0;
          p {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #757575;
            span {
              color: #0043ac;
            }
          }
        }
      }
      .badge-main__det {
        .badge-det__div {
          display: flex;
          label,
          p {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #757575;
          }
          p {
            margin-bottom: 0;
            font-weight: normal;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
@media all and (max-width: 990px) {
  .ob-container {
    .vc-left {
      height: 120px;
    }
    .vc-right {
      height: calc(100% - 120px);
    }
    .vc-left {
      .ob-left__div {
        padding: 20px;
        .align-items-start {
          img {
            width: 65px;
          }
        }
        .align-items-center {
          img {
            width: 89px;
          }
        }
      }
    }
    .vc-right {
      .ob-right__div {
        padding: 12% 5%;
        .verify-credentials {
          .text-left {
            margin-bottom: 50px;
            .vc-notes {
              padding-left: 0;
            }
            .vc-upload__text {
              display: block;
              margin: 20px 0;
            }
            h3 {
              display: flex;
              flex-wrap: wrap;
            }
            .badge {
              margin: 20px 0;
            }
          }
          .ob-btn {
            margin-bottom: 20px;
          }
        }
        .credential-detail {
          overflow: hidden auto;
          &::-webkit-scrollbar {
            visibility: hidden;
          }
          .cd-details {
            .cd-details__links {
              display: block !important;
              a {
                display: block;
                margin-bottom: 24px;
              }
            }
            .cd-details__issue {
              position: relative;
            }
          }
        }
      }
    }
  }
}
</style>
